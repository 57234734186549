<template>
  <div class="dashboard">
    <v-row class="content pb-5">
      <v-col cols="12">
        <h3 v-if="$can('company_access')" class="heading-h3">Dashboard</h3>
        <h3 class="heading-h3">Dashboard</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import ability = require('@/plugins/ability');

// import { AbilityBuilder, Ability } from "@casl/ability";
// import { ABILITY_TOKEN } from "@casl/vue";

export default {
  name: "Dashboard",

  inject: {
    // $ability: { from: ABILITY_TOKEN },
  },

  data: () => ({}),

  created() {
    // let token = localStorage.getItem("token");
    // this.getCategory();
    // localStorage.setItem("token", token);
    // const { can, rules } = new AbilityBuilder(Ability);
    // if (this.$can("category_access")) {
    //   can("category_accessd");
    //   can("category_access");
    // } else {
    //   can("category_access");
    // }
    // this.$ability.update(rules);
    // console.log(this.$can("category_access"));
  },

  methods: {
    async getCategory() {
      const res = await this.axios.get("category/1");

      if (res.status == 200) {
        // console.log(res.data);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard {
  .content {
    height: 100vh;
  }
}
</style>
